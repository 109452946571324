<template>
  <!-- 资料库>目录管理 -->
  <div class="dict">
    <div class="form-header-btn">
      <div class="header-title">目录管理</div>
      <div>
        <el-button type="primary" @click="createChild()" v-if="isAdd"
          >新增目录</el-button
        >
      </div>
    </div>
    <!-- 表单 -->
    <el-form
      :model="form"
      onsubmit="return false"
      :inline="true"
      class="search-form"
    >
      <el-form-item>
        <el-input v-model="form.keyword" placeholder="目录名称" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchKeyword"
          >查询</el-button
        >
      </el-form-item>

      <el-form-item>
        <el-button
          @click="resetTabDate()"
          icon="el-icon-refresh-right"
          class="reset"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <!--table  default-expand-all 展开所有子节点 -->
    <div class="tableList">
      <el-table
        :header-cell-style="{
          background: '#F1F4F9',
          color: '#5E6D9B',
          fontWeight: '400',
          'text-align': 'center',
        }"
        :row-class-name="tableRowClassName"
        :data="tableData"
        v-loading="tableLoading"
        style="width: 100%; margin-bottom: 1.04167vw"
        row-key="id"
        :expand-row-keys="expandRow"
        ref="tableData"
        :default-expand-all="true"
        :tree-props="{ children: 'directoryList', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          prop="directoryName"
          label="目录"
          align="left"
          width="250"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="light"
              popper-class="customPopper"
              :content="scope.row.directoryName"
              placement="top"
              v-if="scope.row.directoryName"
            >
              <div class="itemName">{{ scope.row.directoryName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="visibleRange" label="可见范围" align="center">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="light"
              popper-class="customPopper"
              :content="scope.row.visibleRange"
              placement="top"
              v-if="scope.row.visibleRange"
            >
              <div class="description-div">{{ scope.row.visibleRange }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          width="200"
          align="center"
          label="更新时间"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.updateTime.slice(0, 10) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sort" width="180" align="center" label="排序">
          <template slot-scope="scope">
            <div v-if="scope.row.directoryLevel != 3">
              <el-button
                title="移到顶部"
                icon="el-icon-upload2"
                v-if="scope.row.index != 0"
                size="mini"
                type="text"
                circle
                @click="changeSort('3', scope.row.id)"
              ></el-button>
              <el-button
                title="上移"
                v-if="scope.row.index != 0"
                icon="el-icon-top"
                size="mini"
                type="text"
                circle
                @click="changeSort('1', scope.row.id)"
              ></el-button>
              <el-button
                title="下移"
                icon="el-icon-bottom"
                v-if="scope.row.isLast"
                size="mini"
                type="text"
                circle
                @click="changeSort('2', scope.row.id)"
              ></el-button>
              <el-button
                title="移到底部"
                icon="el-icon-download"
                v-if="scope.row.isLast"
                size="mini"
                type="text"
                circle
                @click="changeSort('4', scope.row.id)"
              ></el-button>
            </div>
            <div v-else>{{ scope.row.sort }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="100" align="center" label="状态">
          <template slot-scope="scope">
            <div v-if="scope.row.directoryLevel == 3">
              <el-link
                type="success"
                :underline="false"
                v-if="scope.row.isEnable == 1"
                >启 用</el-link
              >
              <el-link type="info" :underline="false" v-else>禁 用</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createName"
          width="100"
          align="center"
          label="创建人"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          width="280"
          align="center"
          fixed="right"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              size="small"
              type="text"
              v-if="scope.row.directoryLevel == 3 && isView"
              @click="editDirectory(scope.row, true)"
              >查看</el-button
            >
            <el-button
              size="small"
              type="text"
              v-if="isUpdate && isMine(scope.row.createBy)"
              @click="editDirectory(scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              style="margin: 0 0.52083vw"
              @confirm="delDirectory(scope.row)"
              title="您确定要删除这个目录吗？"
            >
              <el-button
                size="small"
                type="text"
                v-if="isDelete && isMine(scope.row.createBy)"
                slot="reference"
                >删除</el-button
              >
            </el-popconfirm>
            <el-button
              size="small"
              v-if="isAddTj"
              type="text"
              @click="createDirectory(scope.row, 'same')"
              >新增同级</el-button
            >
            <el-button
              size="small"
              type="text"
              v-if="scope.row.directoryLevel != 3 && isAddXj"
              @click="createDirectory(scope.row, 'lower')"
              >新增下级</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div
        class="quick-add-project"
        v-if="!tableLoading && tableData.length == 0 && isAddTj"
        @click="createDirectory(null, 'same')"
      >
        <img :src="iconAdd" alt="" />
        <span>快速创建</span>
      </div>
    </div>
    <el-dialog
      :title="status"
      :visible.sync="dialogVisible"
      width="35%"
      :close-on-click-modal="false"
      @close="closeDialogForm"
    >
      <el-form
        :model="dialogForm"
        label-width="undefined"
        :rules="rules"
        ref="dialogForm"
        width
      >
        <el-form-item class="form-choose-item" prop="directoryName">
          <el-input
            class="form-input"
            v-model.trim="dialogForm.directoryName"
            @keyup="
              dialogForm.directoryName = dialogForm.directoryName.replace(
                /\s+/g,
                ''
              )
            "
            :maxlength="25"
            clearable
            placeholder="请输入目录名称"
          />
          <!-- :onkeyup="(dialogForm.directoryName = dialogForm.directoryName.replace(/\s+/g, ''))" -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="reset" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editOrCreateBtn('dialogForm')"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
import iconAdd from "@/assets/img/icon_add.png"; //添加icon
export default {
  components: {
    // LTable,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      dialogThirdVisible: false,
      dialogVisible: false,
      tableLoading: true, // tableloading
      expandRow: [],
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,

      title: "0",
      form: {
        keyword: "",
        options: [
          {
            label: "异常",
            value: "0",
          },
          {
            label: "正常",
            value: "1",
          },
        ],
      },
      dialogForm: {
        parentId: null,
        directoryName: "",
        id: null,
        directoryLevel: "",
        isEnable: "",
      },
      tableData: [],
      //   pagination: {
      //     //分页信息
      //     total: 30, // 总页数
      //     currentPage: 1, // 当前页数
      //     Size: 10, // 每页显示多少条
      //   },
      // 对话框表单校验
      rules: {
        directoryName: [
          {
            required: true,
            message: "请输入目录名称",
            trigger: "blur",
          },
        ],
      },

      iconAdd, //添加icon

      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      isAddTj: false,
      isAddXj: false,
      isView: false,
      keyword: "",
      status: "",
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("database:edit") != -1; //编辑
          this.isAdd = val.indexOf("database:addCatalog") != -1; //新增
          this.isDelete = val.indexOf("database:del") != -1; //删除
          this.isAddTj = val.indexOf("database:addtj") != -1; //新增同级
          this.isAddXj = val.indexOf("database:addxj") != -1; //新增下级
          this.isView = val.indexOf("database:view") != -1; //查看
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.init();
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },
  methods: {
    /**
     * 判断当前这条记录是否是自己创建的，自己创建的可以编辑，否则不可以
     * 20221020
     */
    isMine(rowUser) {
      return this.userInfo.userId === rowUser;
    },
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      if (this.form.keyword == "") {
        this.form.keyword = null;
      }
      this.tableLoading = true;
      this.$api
        .getSortPageDirectory({ directoryName: this.form.keyword })
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code == "0") {
            this.initTableData(res.data.data);
            this.tableData = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    initTableData(list) {
      const Cdata = list.map((item, index) => {
        const initIndex = (data) => {
          item.index = index;
          if (index == list.length - 1) {
            item.isLast = 0;
          } else {
            item.isLast = 1;
          }
          if (data.directoryList) {
            this.initTableData(data.directoryList);
          }
        };
        initIndex(item);
      });
    },
    resetTabDate(arr) {
      this.form.keyword = "";
      this.init();
      // this.expandRow = [];
      // let data = arr || this.tableData;
      // data.forEach((item) => {
      //   this.$refs.tableData.toggleRowExpansion(item, false);
      //   if (
      //     Object.prototype.toString.call(item.directoryList) == "[object Array]"
      //   ) {
      //     this.resetTabDate(item.directoryList);
      //   }
      // });
    },
    searchKeyword() {
      this.keyword = this.form.keyword;
      // this.init()
      // let expandRow = [],
      //   word = this.form.keyword,
      //   searchTabData = (filed, data) => {
      //     let tableData = data ? data.directoryList : this.tableData || [];
      //     for (let item of tableData) {
      //       if (item.directoryName.indexOf(filed) != -1) {
      //         expandRow.push(item.id + "");
      //         if (data && data.id && expandRow.indexOf(data.id) == -1) {
      //           expandRow.push(data.id + "");
      //           let parent = this.searchParent(data.id);
      //           parent ? expandRow.unshift(parent.id + "") : null;
      //         }
      //       }
      //       if (
      //         Object.prototype.toString.call(item.directoryList) ==
      //         "[object Array]"
      //       ) {
      //         searchTabData(filed, item);
      //       }
      //     }
      //   };
      // searchTabData(word);
      // console.log(expandRow);
      // this.expandRow = expandRow;
    },
    searchParent(id, data) {
      let tableData = data ? data.directoryList : this.tableData || [];
      for (let item of tableData) {
        if (item.id == id) {
          return data || tableData;
        }
        if (
          Object.prototype.toString.call(item.directoryList) == "[object Array]"
        ) {
          let temp = this.searchParent(id, item);
          if (temp) return temp;
        }
      }
      return null;
    },
    saveThirdDirectory() {
      let parmas = {},
        regx = /^.{4,25}$/,
        form = this.dialogForm;
      parmas.parentId = form.parentId;
      parmas.directoryName = form.directoryName;
      parmas.directoryLevel = "3";
      parmas.description = form.description || "";
      parmas.sort = form.sort;
      parmas.visibleRange = form.visibleRange;
      parmas.isEnable = form.isEnable ? "1" : "0";
      if (!parmas.parentId) {
        this.$message.error("系统错误，请重试！");
        this.closeDialogForm();
        return;
      }
      if (!regx.test(parmas.directoryName)) {
        this.$message({ message: "目录名称长度在4-25之间！", type: "warning" });
        return;
      }
      if (!parmas.description) {
        this.$message({ message: "描述不能为空", type: "warning" });
        return;
      }
      if (!parmas.sort) {
        this.$message({ message: "请输入排序数据值！", type: "warning" });
        return;
      }
      if (!parmas.visibleRange) {
        this.$message({ message: "可见范围不能为空", type: "warning" });
        return;
      }
      if (form.id) {
        parmas.id = form.id;
      }
      this.$api.saveOrUpdateDirectory(parmas).then((res) => {
        if (res.data.code == "0") {
          this.$message.success("操作成功");
          this.init();
          this.closeDialogForm();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    changeSort(type, id) {
      let parmas = { id, type };
      this.$api.changeSortDirectory(parmas).then((res) => {
        if (res.data.code == "0") {
          this.$message.success("操作成功");
          this.init();
        } else {
          this.$message.error(res.data.msg);
        }
        // this.init();
      });
    },
    editDirectory(parmas, readonly) {
      if (parmas.directoryLevel == 3) {
        let read;
        if (readonly) {
          read = 2;
        } else {
          read = 1;
        }
        this.$router.push({
          path: "/editCatalogManage",
          query: { id: parmas.id, isEdit: read },
        });
      } else {
        this.editOrCreateDirecctory(
          "edit",
          parmas.id,
          parmas.directoryLevel,
          parmas.directoryName,
          parmas.parentId
        );
      }
    },
    delDirectory(parmas) {
      if (parmas.directoryList && parmas.directoryList.length) {
        this.$message({
          message: "请先删除这个目录下的子目录",
          type: "warning",
        });
        return;
      } else {
        this.$api.delDirectory({ id: parmas.id }).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            // this.page.num = 1;
            this.init();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    editOrCreateBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.saveOrUpdateDirectory(this.dialogForm).then((res) => {
            if (res.data.code == "0") {
              this.$message.success("保存成功");
              this.dialogVisible = false;
              this.init();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    editOrCreateDirecctory(type, id, level, directoryName, partentId) {
      // let title = {
      //   tips: "请输入目录名称",
      //   status: "错误,请关闭窗口",
      // };
      if (type == "create") {
        this.status = "新增";
      }
      if (type == "edit") {
        this.status = "编辑";
      }
      this.dialogVisible = true;
      // this.$prompt(title.tips, title.status, {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   inputPlaceholder:'请输入目标名称',
      //   inputValue:directoryName,
      //   inputPattern: /^[^\s]{4,25}$/,
      //   inputErrorMessage: "不允许输入空格，目录名称长度在4-25之间",
      //   customClass: 'addChild',
      // })
      //   .then(({ value }) => {
      let parmas = {
        // directoryName: value,
      };
      this.dialogForm.directoryName = directoryName;
      if (type == "create") {
        id ? (parmas.parentId = id) : null;
        parmas.isEnable = "1";
        this.dialogForm.isEnable = parmas.isEnable;
        this.dialogForm.parentId = parmas.parentId;
      }
      if (type == "edit") {
        parmas.id = id;
        this.dialogForm.id = parmas.id;
        this.dialogForm.parentId = partentId;
      }
      parmas.directoryLevel = level;
      this.dialogForm.directoryLevel = parmas.directoryLevel;
      // console.log(this.dialogForm)
      //   this.$api.saveOrUpdateDirectory(parmas).then((res) => {
      //     if(res.data.code == '0'){
      //       this.$message.success('修改成功')
      //       this.init();
      //     }else{
      //       this.$message.error(res.data.msg)
      //     }
      //   });
      // })
      // .catch(() => {
      //   // this.$message({
      //   //   type: "info",
      //   //   message: "取消输入",
      //   // });
      // });
    },
    createChild() {
      this.$router.push({ path: "/editCatalogManage", query: { isEdit: 0 } });
    },
    createDirectory(parmas, levelType) {
      // console.log(level,levelType)
      let level, id, parent, parentId;
      if (levelType == "same") {
        level = parmas ? parmas.directoryLevel : null;
        parent = parmas ? this.searchParent(parmas.id) : null;
        id = parent ? parent.id : null;
      } else if (levelType == "lower") {
        level = Number(parmas.directoryLevel) + 1 + "";
        id = parmas.id;
      }
      if (parmas && parmas.directoryLevel == 3) {
        parentId = parmas.parentId;
      } else if (parmas && parmas.directoryLevel == 2) {
        parentId = parmas.id;
      }
      if (level == 3) {
        this.$router.push({
          path: "/editCatalogManage",
          query: { isEdit: 0, parentId: parentId },
        });
        // this.dialogThirdVisible = true;
        // this.dialogForm.list = this.searchParent(id).directoryList;
        // this.dialogForm.parentId = id;
      } else {
        this.editOrCreateDirecctory("create", id, level || "1");
      }
    },
    closeDialogForm() {
      this.dialogForm = {
        parentId: null,
        directoryName: "",
        id: null,
        directoryLevel: "",
        isEnable: "",
      };
      setTimeout(() => {
        this.$refs["dialogForm"].clearValidate();
      }, 100);
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.keyword = "";
      this.page.num = 1;
      this.init();
    },
    /** 分页 */
    handleCurrentChange(val) {
      this.page.num = val;
      this.init();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.num = 1;
      this.init();
    },
    //搜索获取高亮行
    tableRowClassName({ row, rowIndex }) {
      let returnFlag = false;
      // form.keyword
      if (this.keyword) {
        let list = this.keyword;
        let nameList = row.directoryName;
        if (nameList.indexOf(list) != -1) {
          returnFlag = true;
        }
        if (returnFlag) {
          return "search-row"; //light-row
        }
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .tableList {
    width: 100%;
    height: calc(100% - 85px);
    overflow: hidden;
    overflow-y: auto;
  }
  .quick-add-project {
    width: 100%;
    height: 63px;
    border: 1px dashed #b5bdcf;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #003685;
    font-weight: 400;
    margin-top: 5px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
}
.itemName {
  width: 12em;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
  // line-height: 15px;
}
</style>
<style>
.addChild .el-button {
  width: 96px;
  /* height: 38px; */
}
</style>
